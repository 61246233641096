var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建标签","width":1080,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"电表组"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'group_id',
                {
                  rules: [
                    { required: true, message: '必填项，请填写信息' },
                  ],
                },
              ]),expression:"[\n                'group_id',\n                {\n                  rules: [\n                    { required: true, message: '必填项，请填写信息' },\n                  ],\n                },\n              ]"}],attrs:{"option-label-prop":"tt","show-arrow":false,"filter-option":false,"not-found-content":null,"show-search":"","allow-clear":"","placeholder":"请输入电表组","default-active-first-option":false},on:{"search":_vm.groupSearch,"change":_vm.handleGroupChange}},_vm._l((_vm.groupData),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.name}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"企业名称"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'corporation_id',
                {
                  rules: [
                    { required: true, message: '必填项，请填写信息' },
                  ],
                },
              ]),expression:"[\n                'corporation_id',\n                {\n                  rules: [\n                    { required: true, message: '必填项，请填写信息' },\n                  ],\n                },\n              ]"}],attrs:{"option-label-prop":"tt","show-arrow":false,"filter-option":false,"not-found-content":null,"show-search":"","allow-clear":"","placeholder":"请输入企业名称","default-active-first-option":false},on:{"search":_vm.corporationSearch,"change":_vm.corporationChange}},_vm._l((_vm.dataSource),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.name}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['number', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]),expression:"['number', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"资产所有人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'property_owner',
                {
                  rules: [
                    { required: true, message: '必填项，请填写信息' },
                  ],
                },
              ]),expression:"[\n                'property_owner',\n                {\n                  rules: [\n                    { required: true, message: '必填项，请填写信息' },\n                  ],\n                },\n              ]"}],attrs:{"allow-clear":""},on:{"change":_vm.propertyOwnerChange}},_vm._l((this.$Dictionaries.electricity_meter_property_owner),function(val,key){return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(" "+_vm._s(val)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"需付费"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['total_payment', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['total_payment', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"实际付费"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['actual_payment', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['actual_payment', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}]})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }