<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="企业">
              <a-auto-complete
                v-model="queryParam.corporation"
                :data-source="dataSource"
                placeholder="请输入"
                @select="onSelect"
                @search="onSearch"
                allow-clear
              />
              <span>{{ tip }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24" v-if="false">
            <a-form-item label="时间">
              <a-range-picker @change="time" format="YYYY-MM-DD" style="width: 100%">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="使用状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.BillSearchStatus">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="1" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)" style="display: inline">查询</a-button>
          </a-col>
          <a-col :md="1" :sm="24">
            <a-button type="primary" icon="export" @click="handleExcel">导出</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator" v-if="false">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <template slot="footer" slot-scope="currentPageData">
        <a-row style="border-bottom: 1px solid #eeeeee; height: 40px; line-height: 40px;">
          <a-col :span="2">
            当页总计：
          </a-col>
          <a-col :span="4">
            <span>预付金额 {{ totalPayment(currentPageData) | numberFormat}}</span>
          </a-col>
          <a-col :span="4">
            <span>实付金额 {{ actualPayment(currentPageData) | numberFormat}}</span>
          </a-col>
        </a-row>
      </template>
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
            v-if="record.status === 1"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <export-form
      ref="exportModal"
      :visible="exportvisible"
      :loading="confirmLoading"
      @cancel="handleExportCancel"
      @ok="handleExportOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import {
  prepay_bill_list,
  prepay_bill_create,
  prepay_bill_delete,
  prepay_bill_excel_list
} from '@/api/electricity-prepaybill'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import ExportForm from '../modules/ExportForm'
import { corporation_list } from '@/api/corporation'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    ExportForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      exportvisible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      dataSource: [],
      tip: '',
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '电表',
          dataIndex: 'meter_number',
          ellipsis: true
        },
        {
          title: '预付金额',
          dataIndex: 'total_payment',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '实付金额',
          dataIndex: 'actual_payment',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '企业',
          dataIndex: 'corporation_name',
          ellipsis: true
        },
        {
          title: '所有人',
          dataIndex: 'property_owner',
          width: 100,
          align: 'center',
          customRender: (text) => this.$Dictionaries.electricity_meter_property_owner[text]
        },
        {
          title: '状态',
          dataIndex: 'status',
          width: 100,
          align: 'center',
          customRender: (text) => this.$Dictionaries.BillStatus[text]
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return prepay_bill_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    console.log(this.$Dictionaries)
  },
  methods: {
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDelet (record) {
      prepay_bill_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.total_payment = Math.round(values.total_payment * 100)
          values.actual_payment = Math.round(values.actual_payment * 100)
          // 新增
          prepay_bill_create(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
            console.log('删除成功------')
          }).catch(error => {
            console.log(error)
            values.total_payment = (values.total_payment / 100).toFixed(2)
            values.actual_payment = (values.actual_payment / 100).toFixed(2)
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      this.queryParam.corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id
      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    },
    onSearch (searchText) {
      corporation_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的企业'
        } else {
          this.tip = ''
        }
        console.log('我是企业列表', reslut)
        this.dataSource = !searchText ? [] : reslut.map((item) => item.name)
        if (!(this.dataSource.length > 0)) {
          this.queryParam.corporation_id = ''
        }
      })
    },
    handleExcel () {
      console.log('导出')
      // this.exportvisible = true
      this.handleExportOk()
    },
    handleExportCancel () {
      this.exportvisible = false

      const form = this.$refs.exportModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleExportOk () {
      console.log('ok')
      const form = this.$refs.exportModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增 data
          prepay_bill_excel_list(this.queryParam).then((res) => {
            const link = document.createElement('a')
            const blob = new Blob([res], { type: 'application/octet-stream' })
            console.log(blob)
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            link.setAttribute('download', `预付电费账单.xlsx`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    totalPayment (currentPageData) {
      return (currentPageData.reduce((prev, curr) => prev + curr.total_payment, 0) / 100).toFixed(2)
    },
    actualPayment (currentPageData) {
      return (currentPageData.reduce((prev, curr) => prev + curr.actual_payment, 0) / 100).toFixed(2)
    }
  }
}
</script>
