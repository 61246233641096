import request from '@/utils/request'

const prepay_billApi = {
  prepay_bill_list: '/electricity/prepay_bill/',
  prepay_bill_create: 'electricity/prepay_bill/',
  prepay_bill_delete: '/electricity/prepay_bill/',
  prepay_bill_excel_list: '/electricity/prepay_bill/export/',
  electricity_prepay_bill_list: '/electricity/bill/prepay/',
  electricity_prepay_bill_excel_export: '/electricity/bill/prepay/export/'
}

/**
 * 列表
 */
export function prepay_bill_list (parameter) {
  return request({
    url: prepay_billApi.prepay_bill_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function prepay_bill_create (parameter) {
  return request({
    url: prepay_billApi.prepay_bill_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function prepay_bill_delete (prepay_bill_id) {
  return request({
    url: prepay_billApi.prepay_bill_delete + prepay_bill_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 导出
 */
export function prepay_bill_excel_list (parameter) {
  return request({
    url: prepay_billApi.prepay_bill_excel_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}
/**
 * 列表
 */
export function electricity_prepay_bill_list (parameter) {
  return request({
    url: prepay_billApi.electricity_prepay_bill_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 导出
 */
export function electricity_prepay_bill_excel_export (parameter) {
  return request({
    url: prepay_billApi.electricity_prepay_bill_excel_export,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}
