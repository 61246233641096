<template>
  <a-modal
    title="新建标签"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="电表组">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'group_id',
                  {
                    rules: [
                      { required: true, message: '必填项，请填写信息' },
                    ],
                  },
                ]"
                placeholder="请输入电表组"
                :default-active-first-option="false"
                @search="groupSearch"
                @change="handleGroupChange"
              >
                <a-select-option v-for="d in groupData" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="企业名称">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'corporation_id',
                  {
                    rules: [
                      { required: true, message: '必填项，请填写信息' },
                    ],
                  },
                ]"
                placeholder="请输入企业名称"
                :default-active-first-option="false"
                @search="corporationSearch"
                @change="corporationChange"
              >
                <a-select-option v-for="d in dataSource" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="编号">
              <a-input v-decorator="['number', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="资产所有人">
              <a-select
                allow-clear
                v-decorator="[
                  'property_owner',
                  {
                    rules: [
                      { required: true, message: '必填项，请填写信息' },
                    ],
                  },
                ]"
                @change="propertyOwnerChange"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.electricity_meter_property_owner">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="需付费">
              <a-input-number v-decorator="['total_payment', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="实际付费">
              <a-input-number v-decorator="['actual_payment', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { corporation_list } from '@/api/corporation'
import { group_list } from '@/api/electricity-meter-group'
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      groupData: [],
      dataSource: []
    }
  },
  created () {
    this.groupList(undefined)
  },
  methods: {
    groupList (obj) {
      group_list(obj)
        .then(res => {
          this.groupData = res.data.entries
        })
    },
    handleGroupChange (value) {
      console.log(value)
    },
    groupSearch (value) {
      console.log(value)
      this.groupList({ number: value })
    },
    // 公司
    corporationSearch (value) {
      corporation_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        // this.dataSource = !value ? [] : result
        this.dataSource = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    corporationChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handlecorporationSearch(value)
      }
    },
    propertyOwnerChange (value) {
      console.log(value)
    }
  }
}
</script>
